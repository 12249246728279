<template>
  <Fragment>
    <Tabs>
      <Tab :name="mammalsText" selected>
        <div v-if="mammals" class="push-bottom">
          <table v-if="mammals.length > 0" class="table is-fullwidth">
            <thead>
              <tr>
                <td>{{ species }}</td>
                <td>{{ comment }}</td>
                <td></td>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="mammal in mammals"
                :key="mammal.id"
                class="is-clickable"
                @click="toggleSelect(mammal)">
                <td class="is-narrow">
                  <div class="flex align-center">
                    <figure class="image is-32x32 push-right">
                      <img v-if="mammal.iconURL" :src="mammal.iconURL" alt="" />
                    </figure>

                    <label>{{ mammal.name }}</label>
                  </div>
                </td>

                <td>
                  <input
                    v-model="mammal.description"
                    type="text"
                    class="input is-small" />
                </td>

                <td class="is-narrow" style="vertical-align: middle;">
                  <div class="checkbox">
                    <input
                      :id="`mammal-${mammal.id}`"
                      v-model="mammal.selected"
                      type="checkbox"
                      class="styled" />

                    <label :for="`mammal-${mammal.id}`" @click="toggleSelect(mammal)">&nbsp;</label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <p v-else>
            {{ noMammalsAvailable }}
          </p>
        </div>

        <ContentPlaceholders v-else :rounded="true">
          <ContentPlaceholdersText :lines="5" />
        </ContentPlaceholders>
      </Tab>

      <Tab :name="birdsText">
        <div v-if="birds">
          <table v-if="birds.length > 0" class="table is-fullwidth">
            <thead>
              <tr>
                <td>{{ species }}</td>
                <td>{{ comment }}</td>
                <td></td>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="bird in birds"
                :key="bird.id"
                class="is-clickable"
                @click="toggleSelect(bird)">
                <td class="is-narrow">
                  <div class="flex align-center">
                    <figure class="image is-32x32 push-right">
                      <img v-if="bird.iconURL" :src="bird.iconURL" alt="" />
                    </figure>

                    <label>{{ bird.name }}</label>
                  </div>
                </td>

                <td>
                  <input
                    v-model="bird.description"
                    type="text"
                    class="input is-small" />
                </td>

                <td class="is-narrow" style="vertical-align: middle;">
                  <div class="checkbox">
                    <input
                      :id="`bird-${bird.id}`"
                      v-model="bird.selected"
                      type="checkbox"
                      class="styled" />
                    <label :for="`bird-${bird.id}`" @click="toggleSelect(bird)">&nbsp;</label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <p v-else>
            {{ noBirdsAvailable }}
          </p>
        </div>

        <ContentPlaceholders v-else :rounded="true">
          <ContentPlaceholdersText :lines="5" />
        </ContentPlaceholders>
      </Tab>
    </Tabs>
  </Fragment>
</template>

<script>
import { i18n } from '@/i18n'
import store from '@/store/'

export default {
  props: {
    options: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      games: null,
      selected: []
    }
  },

  computed: {
    hunt () { return this.options['hunt'] },
    huntableGames () { return store.getters['hunt/games/games'] },

    isValid () { return this.selected.length },

    selectedGames: {
      get () { return this.options['selectedGames'] },
      set (games) { this.options['selectedGames'] = games }
    },

    availableGames () {
      return this.games && this.huntableGames
        ? this.games.filter(game => !this.huntableGames.some(g => game.id === g.animalId))
        : null
    },

    mammals () {
      return this.availableGames ? this.sortAlphabetically(this.availableGames.filter(game => game.category === 'mammal')) : null
    },

    birds () {
      return this.availableGames ? this.sortAlphabetically(this.availableGames.filter(game => game.category === 'bird')) : null
    },

    species () { return i18n.t('hunt.huntableGames.species') },
    comment () { return i18n.t('hunt.huntableGames.comment') },

    mammalsText () { return i18n.t('hunt.huntableGames.mammals') },
    noMammalsAvailable () { return i18n.t('hunt.huntableGames.noMammalsAvailable') },

    birdsText () { return i18n.t('hunt.huntableGames.birds') },
    noBirdsAvailable () { return i18n.t('hunt.huntableGames.noBirdsAvailable') }
  },

  mounted () {
    this.read()
  },

  methods: {
    async read () {
      try {
        const county = await store.dispatch('hunt/games/readCounty', this.hunt.huntAreaId)
        const countryCode = this.$store.getters['ui/getCountryCode']

        this.games = await store.dispatch('hunt/games/readAvailable', {
          county: county,
          date: this.$dayjs(this.hunt.startsAt).format('YYYY-MM-DD'),
          countryCode: countryCode
        })
      } catch (error) {
        console.error(error)
      }
    },

    toggleSelect (game) {
      const i = this.selected.indexOf(game)

      if (i !== -1) {
        game.selected = false
        this.selected.splice(i, 1)
      } else {
        game.selected = true
        this.selected.push(game)
      }

      this.selectedGames = this.selected
    },

    sortAlphabetically (items) {
      return items.sort((a, b) => a.name.localeCompare(b.name))
    }
  }
}
</script>
