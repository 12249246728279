<template>
  <Fragment>
    <Label :label="$t('hunt.huntableGames.title')" />

    <p v-if="canEdit">
      {{ $t('hunt.huntableGames.helpText') }}
    </p>

    <InlineLoader v-if="isLoading" />

    <div v-else>
      <Table v-if="huntableGames.length > 0" :hoverable="canEdit" :bordered="!canEdit">
        <TableHeader>
          <TableHeaderCell :heading="$t('hunt.huntableGames.species')" />
          <TableHeaderCell :heading="$t('hunt.huntableGames.comment')" />
          <TableHeaderCell v-if="canEdit" />
        </TableHeader>

        <TableContent>
          <TableRow
            v-for="game in huntableGames"
            :key="game.id"
            :class="{
              'is-dimmed': game.removing || game.adding,
              'is-clickable': game.id && !game.editable && canEdit
            }"
            @click="edit(game)">
            <TableCell class="is-narrow">
              {{ game.name }}
            </TableCell>

            <TableCell class="has-text-grey">
              <div v-if="game.editable" class="flex align-center justify-between">
                <div style="flex-grow: 1;">
                  <input v-model="game.note" type="text" class="input is-small" />
                </div>

                <div class="buttons are-small flex push-left-xs">
                  <Button @click.stop="update(game)">
                    <Icon name="icon-save" class="has-text-success" />
                  </Button>

                  <Button @click.stop="cancel(game)">
                    <Icon name="icon-x" class="has-text-danger" />
                  </Button>
                </div>
              </div>

              <div v-else style="white-space: normal;">
                <small>{{ game.note }}</small>
              </div>
            </TableCell>

            <TableCell v-if="canEdit">
              <div v-if="!game.editable && !isFinished" class="is-pulled-right">
                <span v-if="game.id && !isFinished" class="is-clickable">
                  <Icon name="icon-edit-2" />
                </span>

                <span v-if="game.id && !isFinished" class="is-clickable" @click.stop="confirmRemove(game)">
                  <Icon name="icon-trash" class="has-text-danger" />
                </span>
              </div>
            </TableCell>
          </TableRow>
        </TableContent>
      </Table>

      <span v-if="!canEdit && huntableGames.length === 0" class="has-text-grey">
        {{ $t('hunt.huntableGames.noHuntableGamesSelected') }}
      </span>

      <FormField v-if="canEdit">
        <Button
          :disabled="!hasDate"
          @click="addGame">
          <Icon name="icon-plus-circle" class="has-text-success" />
          <span>{{ $t('hunt.huntableGames.add') }}</span>
        </Button>

        <p v-if="!hasDate" class="help has-text-grey" v-html="$t('hunt.noDateSelected')"></p>
      </FormField>
    </div>
  </Fragment>
</template>

<script>
import { mapGetters } from 'vuex'
import GameModal from './GameModal.vue'
import HuntGameModel from '@/api/hunt/games/huntGameModel'

export default {
  data () {
    return {
      huntId: this.$route.params.huntId
    }
  },

  static () {
    return {
      temporary: {
        note: null
      }
    }
  },

  computed: {
    ...mapGetters({
      hunt: 'hunt/hunt',
      huntableGames: 'hunt/games/games',
      canEdit: 'hunt/canEdit'
    }),

    hasDate () {
      return this.hunt
        ? this.hunt.startsAt && this.hunt.endsAt
        : false
    },

    isPlanning () { return this.hunt && this.hunt.status === 'planning' },
    isPlanned () { return this.hunt && this.hunt.status === 'planned' },
    isFinished () { return this.hunt && this.hunt.status === 'finished' },

    isLoading () {
      return this.huntableGames === null
    }
  },

  methods: {
    edit (game) {
      if (this.canEdit) {
        this.temporary.note = game.note
        game.editable = true
      }
    },

    cancel (game) {
      game.editable = false
      game.note = this.temporary.note
      this.temporary.note = null
    },

    async update (game) {
      try {
        await this.$store.dispatch('hunt/games/update', {
          huntId: this.huntId,
          game: game
        })

        game.editable = false
      } catch (error) {
        this.$notification.danger(this.$i18n.t('general.errorOccured'))
        console.error(error)
      }
    },

    async confirmRemove (game) {
      const response = await this.$dialog.confirm({
        title: this.$t('general.remove'),
        message: this.$t('general.reallyRemove', { name: game.name }),
        ok: this.$t('general.remove')
      })

      if (response.ok) this.remove(game)
    },

    async remove (game) {
      try {
        await this.$store.dispatch('hunt/games/delete', game)
      } catch (error) {
        this.$notification.danger(this.$i18n.t('hunt.huntableGames.removeFailedErrorMessage'))
        console.error(error)
      }
    },

    async addGame () {
      const response = await this.$dialog.prompt({
        title: this.$t('hunt.huntableGames.title'),
        component: GameModal,
        ok: this.$t('general.add'),
        data: {
          hunt: this.hunt,
          selectedGames: null
        }
      })

      const { selectedGames } = response.dialog.data

      if (response.ok) {
        selectedGames.forEach(game => this.create(game))
      }
    },

    async create (game) {
      try {
        const huntGame = new HuntGameModel({
          animalId: game.id,
          name: game.name,
          category: game.category,
          note: game.description
        })

        await this.$store.dispatch('hunt/games/create', {
          huntId: this.huntId,
          game: huntGame
        })
      } catch (error) {
        this.$notification.danger(this.$i18n.t('hunt.huntableGames.addFailedErrorMessage'))
        console.error(error)
      }
    }
  }
}
</script>
