<template>
  <div v-if="hunt" class="general-tab">
    <h5 class="title is-5">
      {{ $t('hunt.overview') }}
    </h5>

    <p v-if="canEdit" class="subtitle is-6">
      {{ $t('hunt.overviewHelpText') }}
    </p>

    <FormField :label="$t('report.title')" :mandatory="canEdit">
      <TextInputField
        v-if="canEdit"
        v-model="hunt.name"
        :placeholder="$t('report.titlePlaceholder')"
        @input="updateTimer" />

      <Paragraph v-else :text="hunt.name" />
    </FormField>

    <Divider />

    <div class="columns">
      <div class="column">
        <Label :label="$t('hunt.startsAt')" :mandatory="canEdit" />

        <FlatPickr
          v-if="canEdit"
          v-model="startDate"
          :config="configStartDate"
          :placeholder="today"
          class="input"
          @on-close="updateStartsAt">
        </FlatPickr>

        <span v-else>
          {{ date(startDate) }}
        </span>
      </div>

      <div class="column">
        <Label :label="$t('hunt.endsAt')" :mandatory="canEdit" />

        <FlatPickr
          v-if="canEdit"
          v-model="endDate"
          :config="configEndDate"
          :placeholder="tomorrow"
          class="input"
          :disabled="!startDate"
          @on-close="updateEndsAt">
        </FlatPickr>

        <span v-else>
          {{ date(endDate) }}
        </span>
      </div>
    </div>

    <Divider />

    <Label :label="$t('hunt.invitation')" :mandatory="canEdit" />

    <div v-if="canEdit">
      <p class="subtitle is-6">
        {{ $t('hunt.invitationHelpText') }}
      </p>

      <TextArea
        v-model="hunt.note"
        :rows="7"
        @input="updateTimer">
      </TextArea>
    </div>

    <div v-else>
      <div v-if="hunt.note.length > 0" v-html="hunt.note" />
      <span v-else>Ingen inbjudan angiven.</span>
    </div>

    <Divider />

    <Games v-if="hunt" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import dayjsPluginUTC from 'dayjs-plugin-utc'
import Games from './games/Games.vue'

dayjs.extend(dayjsPluginUTC, { parseToLocal: true })

export default {
  components: {
    Games
  },

  data () {
    return {
      startDate: null,
      endDate: null,
      timeout: null,
      editorOptions: [
        'bold',
        'italic',
        'underline',
        {
          name: 'link',
          result: () => {
            const url = window.prompt('URL')
            if (url) window.pell.exec('createLink', this.ensureHTTP(url))
          }
        }
      ],
      editorPlaceholder: '',
      editorClasses: {
        actionbar: 'pell-actionbar',
        button: 'pell-button',
        content: 'pell-content',
        selected: 'pell-button-selected'
      }
    }
  },

  computed: {
    ...mapGetters({
      hunt: 'hunt/hunt',
      isLoading: 'hunt/isLoading',
      canEdit: 'hunt/canEdit',
      boundary: 'hunt/map/boundary'
    }),

    state: {
      get () { return this.$store.getters['hunt/state'] },
      set (state) { return this.$store.dispatch('hunt/state', state) }
    },

    hasDate () {
      return this.hunt
        ? this.hunt.startsAt && this.hunt.endsAt
        : false
    },

    configStartDate () {
      return {
        enableTime: true,
        time_24hr: true,
        dateFormat: 'Y-m-d H:i',
        disable: [{
          from: dayjs().subtract(10, 'year').format('YYYY-MM-DD'),
          to: dayjs().subtract(1, 'day').format('YYYY-MM-DD')
        }]
      }
    },

    configEndDate () {
      const from = this.hunt.startsAt ? dayjs(this.hunt.startsAt) : dayjs()

      return {
        enableTime: true,
        time_24hr: true,
        dateFormat: 'Y-m-d H:i',
        defaultHour: 13,
        minTime: from.add(1, 'hour').format('H:i'),
        disable: [{
          from: from.subtract(10, 'year').format('YYYY-MM-DD'),
          to: from.subtract(1, 'day').format('YYYY-MM-DD')
        }]
      }
    },

    isPlanning () { return this.hunt && this.hunt.status === 'planning' },
    isPlanned () { return this.hunt && this.hunt.status === 'planned' },
    isFinished () { return this.hunt && this.hunt.status === 'finished' },

    today () { return dayjs().format('YYYY-MM-DD') },
    tomorrow () { return dayjs().add(1, 'day').format('YYYY-MM-DD') }
  },

  watch: {
    hunt (hunt) {
      if (hunt) {
        this.startDate = (this.hunt && this.hunt.startsAt) || null
        this.endDate = (this.hunt && this.hunt.endsAt) || null
      }
    }
  },

  created () {
    this.startDate = (this.hunt && this.hunt.startsAt) || null
    this.endDate = (this.hunt && this.hunt.endsAt) || null
  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      // center on entire hunting area
      if (vm.boundary) vm.boundary.selected = true
    })
  },

  methods: {
    date (date) {
      return dayjs(date).format('YYYY-MM-DD HH:mm')
    },

    updateTimer () {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.update, 1000)
    },

    async update () {
      this.state = 'loading'

      try {
        await this.$store.dispatch('hunt/update', this.hunt)
      } catch (error) {
        this.$notification.danger(this.$i18n.t('general.errorOccured'))
        console.error(error)
      }

      this.state = ''
    },

    ensureHTTP (url) {
      if (!/^(?:f|ht)tps?:\/\//.test(url)) {
        url = 'http://' + url
      }

      return url
    },

    updateStartsAt (startsAt) {
      this.hunt.startsAt = dayjs(startsAt).toDate()
      this.update()
    },

    updateEndsAt (endsAt) {
      this.hunt.endsAt = dayjs(endsAt).toDate()
      this.update()
    }
  }
}
</script>

<style lang="scss">
.pell-content {
  height: 200px;
  background: white;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.pell-actionbar {
  background: rgb(245, 245, 245);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-color: rgb(209, 216, 224);
}

.vp-editor {
  border-radius: 4px;
  border: 1px solid rgb(209, 216, 224);
  color: #363636;
}

.general-tab hr {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
</style>
